import React from "react";
import { Link } from "react-router-dom";
import logoSvg from "../assets/logo.png";
import "./component.css";
const Logo = () => {
  return (
    <Link
      to="/"
      className="
     absolute top-[0.5rem] left-[0.5rem] [text-decoration:none] text-cyan flex items-center LogoClass"
    >
      <img src={logoSvg} alt="coinslions" width={180} height={180} />
      {/* <span>CryptoBucks</span> */}
    </Link>
  );
};

export default Logo;
