import React, { useContext, useState } from "react";
import Logo from "../components/Logo";
import "./NavBar.css";
import searchIcon from "../assets/search-icon.svg";
import starT from "../assets/starT.svg";
import santaclaus from "../assets/santaclaus.svg";
import picklist from "../assets/picklist.svg";
import plus from "../assets/plus.svg";
import cancel from "../assets/cancel.svg";
import { NavLink } from "react-router-dom";
import Search from "../components/Search";
import { CryptoContext } from "./../context/CryptoContext";
import "../components/component.css";
import "../components/bigcomponent.css";
import debounce from "lodash.debounce";


const SearchInput = ({ handleSearch }) => {
  const [searchText, setSearchText] = useState("");
  let { searchData, setCoinSearch, setSearchData } = useContext(CryptoContext);

  let handleInput = (e) => {
    e.preventDefault();
    let query = e.target.value;
    setSearchText(query);
    handleSearch(query);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchText);
  };

  const selectCoin = (coin) => {
    setCoinSearch(coin);
    setSearchText("");
    setSearchData();
  };

  return (
    <>
     <form onSubmit={handleSubmit} className="flex items-center max-w-sm mx-auto">
    
      <div className="relative w-full">
        <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
            />
          </svg>
        </div>
        <input
          type="text"
          id="simple-search"
          onChange={handleInput}
          value={searchText} 
          className="bg-primary-50 border border-transparent text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-cyan  block w-full ps-10 p-2.5 dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text- dark:focus:border-black-800  dark:focus:border-primary-500 required outline-0"
          placeholder="Search coin..."
          required
        />
      </div>
      <button
        type="submit"
        className="p-2.5 ms-2 text-sm font-medium text-white bg-primary-300 rounded-lg border border-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-primary-300 dark:hover:bg-primary-200 dark:focus:ring-primary-800 cursor-pointer"
      >
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
        <span className="sr-only">Search</span>
      </button>
    </form>
        {/* <button type="submit" className="absolute cursor-pointer right-1">
          <img src={searchIcon} className="w-full h-auto" alt="search" />
        </button> */}
    
      {searchText.length > 0 ? (
        <ul
          className="absolute z-20 py-2 overflow-x-hidden bg-gray-200 rounded top-11 right--20 w-96 h-96 bg-opacity-60 backdrop-blur-md scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-200 "
        >
          {searchData ? (
            searchData.map((coin) => {
              return (
                <li
                  className="flex items-center my-2 ml-4 cursor-pointer"
                  key={coin.id}
                  onClick={() => selectCoin(coin.id)}
                >
                  <img
                    className="w-[1rem] h-[1rem] mx-1.5 rounded-lg"
                    src={coin.thumb}
                    alt={coin.name}
                    width={25}
                    height={25}
                  />

                  <span className="text-black-700">{coin.name}</span>
                </li>
              );
            })
          ) : (
            <div
              className="flex items-center justify-center w-full h-full "
            >
              <div
                className="w-8 h-8 border-4 rounded-full border-cyan border-b-gray-200 animate-spin "
                role="status"
              />
              <span className="ml-2 text-primary-800">Searching...</span>
            </div>
          )}
        </ul>
      ) : null}
    </>
  );
};


const NavBar = () => {
  let { getSearchResult } = useContext(CryptoContext);

  const debounceFunc = debounce(function (val) {
    getSearchResult(val);
  }, 2000);

  
  const [openDropDown, setOpenDropDown] = useState(false);

  const handleOpenDrop = () => {
    setOpenDropDown(!openDropDown);
  };
  return (
    <>
      <section className="NavBar-Section">
        <nav className="NavBar-Body">
          <div
            style={{
              display: "flex",
              flex: "2",
              justifyContent: "space-between",
            }}
            className=""
          >
            <div className="NavBar-Logo">
              <Logo  />
            </div>

            <div className=" NavBar-Items HiddenUntilS">
            <div className="relative justify-center w-60">
      <SearchInput handleSearch={debounceFunc} />
    </div>
            <NavLink
        to="/"
        
      >
              <div>
                <p>Cryptocurrencies</p>
              </div>
              </NavLink>
              <NavLink
        to="trending"
        
      >
              <div className="disappear">
                <p>Trending Coin</p>
              </div>
              </NavLink>
           <div>
                <p>Learn</p>
              </div>
             
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flex: "1",
              alignContent: "center",
              fontWeight: "600",
              fontSize: "17px",
              color: "#000000",
             
              
            }}
          >
        
              <NavLink
        to="/saved"
        
      >
            <div className="HiddenUntilS smallFlex">
            <img src={starT} width="25px" alt="" />&nbsp;
              <p>Portfolio</p>
            </div>
            </NavLink>

            <div
              onClick={handleOpenDrop}
              style={{ minWidth: "40px" }}
              className="HiddenUntil"
            >
              {!openDropDown && <img src={picklist} width="25px" alt="" />}{" "}
              {openDropDown && <img src={cancel} width="25px" alt="" />} &nbsp;
            </div>
          </div>
        </nav>

        {openDropDown && (
          <>
            <section className="DropdownSection">
            <NavLink
        to="/"
        
      >
              <div className="NavBar-DropItem">
                <p>Cryptocurrencies</p>
                <div>
                  <img
                    style={{ marginTop: "20px" }}
                    src={plus}
                    width="25px"
                    alt=""
                  />{" "}
                  &nbsp;
                </div>
              </div>
              </NavLink>
              <NavLink
        to="trending"
        
      >
              <div className="NavBar-DropItem">
                <p>Trending Coin</p>
                <div>
                  <img
                    style={{ marginTop: "20px" }}
                    src={plus}
                    width="25px"
                    alt=""
                  />{" "}
                  &nbsp;
                </div>
              </div>
              </NavLink>
              
              <div className="NavBar-DropItem">
                <p>Learn</p>
                <div>
                  <img
                    style={{ marginTop: "20px" }}
                    src={plus}
                    width="25px"
                    alt=""
                  />{" "}
                  &nbsp;
                </div>
              </div>
              <NavLink
        to="/saved"
        
      >
              <div className="NavBar-DropItem">
                <div className="smallFlex">
                <img src={starT} width="25px" alt="" /> &nbsp;
                  <p>Portfolio</p>
                </div>
                <div>
                  <img
                    style={{ marginTop: "20px" }}
                    src={plus}
                    width="25px"
                    alt=""
                  />{" "}
                  &nbsp;
                </div>
              </div>
              </NavLink>
              <div className="relative">
      <SearchInput handleSearch={debounceFunc} />
    </div>

               {/* <div className="BigButtonNav">
                <button className="Nav-DropdownBTN">Log In</button>
              </div> */}

              {/* <div className="DropDown-NavBTNS">
                <div className="BigButtonNav">
                  <button className="Nav-DropdownGroup">EN</button>
                </div>
                &nbsp;
                <div className="BigButtonNav">
                  <button className="Nav-DropdownGroup">USD</button>
                </div>
                &nbsp;
                <div className="BigButtonNav">
                  <button className="Nav-DropdownGroup">Light/Dark mode</button>
                </div>
              </div> */}
            </section>
          </>
        )}
      </section>
    </>
  );
};

export default NavBar;
