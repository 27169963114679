import React, { useContext, useEffect, useState } from "react";
import fire from "../assets/fire.svg";
import rightarrow from "../assets/rightarrow.svg";
import arrowup from "../assets/arrowup.svg";
import up from "../assets/up.svg";
import carousel1 from "../assets/carousel1.jpg";
import carousel2 from "../assets/carousel2.jpeg";
import carousel3 from "../assets/carousel3.webp";
import down from "../assets/down.svg";
import "./HeroSection.css";
import { TrendingContext } from "../context/TrendingContext";
import { CryptoContext } from "../context/CryptoContext";
import { useNavigate } from "react-router-dom";
const HeroSection = () => {
  const { trendData } = useContext(TrendingContext);
  const { cryptoData } = useContext(CryptoContext);
  const [activeIndex, setActiveIndex] = useState(1);

  const navigate = useNavigate();

  const navigateToAll = () => {
    navigate("/");
  };

  const navigateToTrending = () => {
    navigate("/trending");
  };


  const images = [
    carousel1,
    carousel2,
    carousel3,


  ];

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="HeroBodyMain">



<div id="controls-carousel" className="relative w-full" data-carousel="static">
      {/* Carousel wrapper */}
      <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
        {images.map((src, index) => (
          <div
            key={index}
            className={`duration-700 ease-in-out ${index === activeIndex ? "block" : "hidden"}`}
            data-carousel-item={index === activeIndex ? "active" : ""}
          >
            <img
              src={src}
              className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </div>
      {/* Slider controls */}
      <button
        type="button"
        className="absolute top-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer start-0 group focus:outline-none"
        onClick={prevSlide}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white dark:bg-white group-hover:bg-white dark:group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-200/70 group-focus:outline-none">
          <svg
            className="w-4 h-4 text-white dark:text-primary-900 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className="absolute top-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer end-0 group focus:outline-none"
        onClick={nextSlide}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white dark:bg-white group-hover:bg-white dark:group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-200 group-focus:outline-none">
          <svg
            className="w-4 h-4 text-white dark:text-primary-900 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>




      <div className="pl-4 mt-5 ml-5 ">
        <p className="font-bold text-[30px] text-black-500">
          Cryptocurrency Prices by Market Cap
        </p>
        <p className="text-black" style={{ color: "#111112" }}>
          The global cryptocurrency market cap today is $2.2 Trillion, a 0.7%
          change in the last 24 hours.
          {/* <a href="8" className="ml-1 underline text-green">
            Read more
          </a> */}
        </p>
      </div>
      <div className="HeroBody">
      <div className="flex pl-4 mt-10 justify-evenly HeroBodyOne">
          <div className="items-center justify-center p-4 rounded-lg HeroBodyOneItem">
            <div
              style={{ cursor: "pointer" }}
              className="flex justify-between mb-3 "
            >
              <div className="HeroBodyOneItem-Names">
                <img width="30px" src={fire} alt="fire" />
                &nbsp; &nbsp;
                <p className="text-primary-600 ">Trending</p>
              </div>
              <div
                onClick={navigateToTrending}
                className="HeroBodyOneItem-Names"
              >
                <p style={{ marginTop: "3px" }} className="text-primary-600">
                  View more
                </p>
                <img width="30px" className="" src={rightarrow} alt="" />
              </div>
            </div>
            {trendData &&
              trendData.slice(0, 3).map((coin) => {
                const oldPrice = coin.item.data.price; // Replace with the actual key for old price
                const newPrice = coin.item.data.price_change_percentage_24h.usd;
                const percentageChange =
                  ((newPrice - oldPrice) / oldPrice) * 100;
                const isPositive = percentageChange >= 0;
                const arrowIcon = isPositive ? up : down;

                return (
                  <div
                    className="flex justify-between mt-5"
                    key={coin.item.coin_id}
                  >
                    <div className="HeroBodyOneItem-Names">
                      <img
                        width="30px"
                        className="rounded-lg mr-2"
                        src={coin.item.small}
                        alt="coin"
                      />
                      &nbsp;
                      <p className="text-black-500">{coin.item.name}</p>
                    </div>
                    <div className="HeroBodyOneItem-Names">
                      <p className="text-black-600">{coin.item.data.price.toFixed(8)}</p>
                      &nbsp;
                      <img
  width={20}
  height={20}
  className="rounded-lg"
  src={arrowIcon}
  alt={isPositive ? "up" : "down"}
/>

                    
                      &nbsp;
                      <p className={percentageChange > 0 ? 'text-success-600' : 'text-danger-500'}>
  {percentageChange.toFixed(2)}%
</p>

                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="flex pl-4 mt-10 justify-evenly HeroBodyOne">
          <div className="items-center justify-center p-4 rounded-lg HeroBodyOneItem">
            <div
              style={{ cursor: "pointer" }}
              className="flex justify-between mb-3 "
            >
              <div className="HeroBodyOneItem-Names">
                <img width="30px" src={fire} alt="fire" />
                &nbsp; &nbsp;
                <p className="text-primary-500 ">All Coins</p>
              </div>
              <div onClick={navigateToAll} className="HeroBodyOneItem-Names">
                <p style={{ marginTop: "3px" }} className="text-primary-500">
                  View more
                </p>
                <img width="30px" className="" src={rightarrow} alt="" />
              </div>
            </div>
            {cryptoData &&
              cryptoData.slice(0, 3).map((coin) => {
                const isPositive = coin.price_change_percentage_24h >= 0;
                const arrowIcon = isPositive ? up : down;

                return (
                  <div className="flex justify-between mt-5" key={coin.id}>
                    <div className="HeroBodyOneItem-Names">
                      <img
                        width="30px"
                        className="rounded-lg mr-2"
                        src={coin.image}
                        alt="coin"
                      />
                      &nbsp;
                      <p className="text-black-500">{coin.name}</p>
                    </div>
                    <div className="HeroBodyOneItem-Names">
                      <p className="text-black-600">{coin.current_price}</p>
                      &nbsp;
                      <img
                        width="20px"
                        src={arrowIcon}
                        alt={isPositive ? "up" : "down"}
                      />
                      &nbsp;
                      <p className={coin.price_change_percentage_24h > 0 ? 'text-success-600' : 'text-danger-500'}>
  {coin.price_change_percentage_24h.toFixed(2)}%
</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
