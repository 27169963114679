import React from "react";
const Footer = () => {
  return (
    <div style={{ width: "90%", margin: "auto", paddingBottom: "25px" }}>
      <p>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "17px",
            borderBottom: "1px solid white",
            fontStyle: "normal",
            marginRight: "5px",
            color: "red",
          }}
        >
         IMPORTANT DISCLAIMER:{" "}
        </span>
        <span style={{ fontSize: "13px" }} className="text-black-800">
          All content provided herein our website, hyperlinked sites, associated
          applications, forums, blogs, social media accounts and other platforms
          (“Site”) is for your general information only, procured from third
          party sources. We make no warranties of any kind in relation to our
          content, including but not limited to accuracy and updatedness. No
          part of the content that we provide constitutes financial advice,
          legal advice or any other form of advice meant for your specific
          reliance for any purpose. Any use or reliance on our content is solely
          at your own risk and discretion. You should conduct your own research,
          review, analyse and verify our content before relying on them. Trading
          is a highly risky activity that can lead to major losses, please
          therefore consult your financial advisor before making any decision.
          No content on our Site is meant to be a solicitation or offer.
        </span>
      </p>
      <div className="absolute flex items-center bottom-4 right-8">
 
    <a
  className="px-1 text-lg"
  target={"_blank"}
  rel="noreferrer"
  href="https://x.com/Coinslions?t=VVjaoKEW70uBhwOfGUQhuQ&s=09"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    style={{
      msTransform: "rotate(360deg)",
      WebkitTransform: "rotate(360deg)",
      transform: "rotate(360deg)",
    }}
  >
    <path
      fill="currentColor"
      className="fill-primary-900"
      d="M22.46 6.011c-.793.351-1.644.588-2.53.693a4.415 4.415 0 001.937-2.437 8.81 8.81 0 01-2.793 1.064 4.409 4.409 0 00-7.594 3.016c0 .345.039.681.112 1.002A12.49 12.49 0 013.066 5.18a4.409 4.409 0 001.37 5.88 4.405 4.405 0 01-2.002-.552v.056a4.409 4.409 0 003.534 4.325 4.444 4.444 0 01-2 .076 4.41 4.41 0 004.12 3.06 8.845 8.845 0 01-6.508 1.816 12.48 12.48 0 006.766 1.983c8.118 0 12.551-6.732 12.551-12.557 0-.192-.003-.383-.011-.573a8.969 8.969 0 002.204-2.288"
    />
    <path fill="rgba(0, 0, 0, 0)"  d="M0 0h24v24H0z" />
  </svg>
</a>


<a
  className="px-1 text-lg"
  target={"_blank"}
  rel="noreferrer"
  href="https://t.me/coinslions"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    style={{
      msTransform: "rotate(360deg)",
      WebkitTransform: "rotate(360deg)",
      transform: "rotate(360deg)",
    }}
  >
    <path
      fill="currentColor"
      className="fill-primary-900"
      d="M12 0C5.373 0 0 5.373 0 12c0 3.314 1.353 6.313 3.515 8.485C2.83 24.643 0 25.97 0 25.97c4.566-.047 7.46-1.84 8.297-2.262C9.642 24.874 10.812 25 12 25c6.627 0 12-5.373 12-12S18.627 0 12 0zm5.564 9.195l-1.537 7.252c-.115.515-.415.644-.839.4l-2.321-1.717-1.117 1.065c-.185.185-.34.34-.694.34l.248-3.526 6.42-5.805c.28-.233-.062-.362-.435-.13l-7.93 4.998L7.21 10.47c-.467-.185-.477-.466.1-.694l9.184-3.543c.417-.166.777.1.636.962z"
    />
  </svg>
</a>

</div>
    </div>

    
  );
};

export default Footer;